import * as React from 'react';
import {Box, Button, Cluster, FormItem, Stack, TextInput} from '@pluto-tv/assemble';

import {msToTimecode, timecodeToMs} from 'helpers/msToTimecode';

export interface IAdPodValue {
  duration: number;
  startAt: number;
}

interface IAdPodFormProps {
  value?: IAdPodValue;
  fixedPlaceholder: string;
  mask: string;
  visible?: boolean;
  framerate: number;
  isValid(startsAt?: string): string | undefined;
  onPin(): number;
  onCancel(): void;
  onSubmit(obj: IAdPodValue): void;
}

const AdPodForm = React.memo(
  ({value, fixedPlaceholder, mask, visible, onPin, onCancel, onSubmit, isValid, framerate}: IAdPodFormProps) => {
    const [startsAt, setStartsAt] = React.useState<string>();
    const [duration, setDuration] = React.useState<number>(120);

    const [durationError, setDurationError] = React.useState<string>();

    React.useEffect(() => {
      if (visible) {
        setStartsAt(value ? msToTimecode(value.startAt * 1000, framerate) : undefined);
        setDuration(value ? value.duration : 120);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible, value]);

    React.useEffect(() => {
      setDurationError(undefined);

      if (!Number.isFinite(duration)) {
        setDurationError('Duration is invalid');
        return;
      }

      if (duration < 15) {
        setDurationError('Duration must be at least 15 seconds');
        return;
      }

      if (duration > 600) {
        setDurationError('Duration cannot be greater than 600 seconds');
        return;
      }

      if (duration % 15 !== 0) {
        setDurationError('Duration must be a multiple of 15');
        return;
      }
    }, [duration]);

    const startsAtError = React.useMemo(() => isValid(startsAt), [startsAt, isValid]);

    return (
      <Box id='addAdPodContent' padding='small' background='charcoal'>
        <Stack space='small'>
          <FormItem label='Time Stamp' state={startsAtError ? 'error' : ''} helpText={startsAtError}>
            <TextInput
              id='timestampInput'
              iconRight='playhead'
              onIconRightClick={() => setStartsAt(msToTimecode(onPin(), framerate))}
              fixedPlaceholder={fixedPlaceholder}
              mask={mask}
              value={startsAt}
              onChange={val => setStartsAt(val)}
            />
          </FormItem>
          <FormItem label='Duration' state={durationError ? 'error' : ''} helpText={durationError}>
            <TextInput id='durationInput' value={duration} onChange={val => setDuration(val)} type='number' step={15} />
          </FormItem>
          <Cluster justify='space-between'>
            <div></div>
            <Cluster space='small'>
              <Button id='adPodCancelBtn' ghost={true} onClick={onCancel}>
                Cancel
              </Button>
              <Button
                id='adPodAddBtn'
                type='primary'
                state={startsAtError || durationError || !startsAt || startsAt.length < 11 ? 'disabled' : ''}
                onClick={() =>
                  onSubmit({
                    duration,
                    startAt: timecodeToMs(startsAt!, framerate) / 1000,
                  })
                }
              >
                {!value ? '+ Add' : 'Update'}
              </Button>
            </Cluster>
          </Cluster>
        </Stack>
      </Box>
    );
  },
);

AdPodForm.displayName = 'AdPodForm';
export default AdPodForm;
